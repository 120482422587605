let OSS = require("ali-oss");

let client = new OSS({
  region: "oss-cn-shenzhen", //创建的时候，bucket所在的区域，华北2->oss-cn-beijing ；其他的可以去百度
  accessKeyId: "LTAI5tFgaoAD4cJBAJAtsNdy", // 阿里云控制台创建的AccessKey
  accessKeySecret: "arpoLtTcYX4i6CjZNh0IU5YcK3wSYR", //阿里云控制台创建的AccessSecret
  bucket: "xingge-ai", //创建的bucket的名称
});

export const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl, {
      timeout: 600000,
    });
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
    return result;
  } catch (e) {
    console.log(e);
  }
};

// 上传成功之后，转换真实的地址
export const signatureUrl = async (ObjName) => {
  try {
    let result = await client.signatureUrl(`${ObjName}`);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return `${+new Date()}_${rx()}${rx()}`;
};
