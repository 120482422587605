import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import baseUrl from "@/utils/config.js";
// import { config } from 'vue/types/umd'
// import Qs from "qs";

// 是否显示重新登录
export let isRelogin = { show: false };

// 创建axios
const service = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
});

// post请求头
service.defaults.headers.post["Content-Type"] = "application/json";

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 为请求头对象添加token验证
    if (config.url.indexOf("http") !== -1) {
      config.baseURL = "";
    }
    if (localStorage.getItem("agent-token")) {
      config.headers.token = localStorage.getItem("agent-token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    console.log("服务器响应成功");
    if (res.data.code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        ElMessageBox.confirm(
          "登录状态已过期，您可以继续留在该页面，或者重新登录",
          "系统提示",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            console.log("重新登录");
            localStorage.removeItem("agent-token");
            location.href = "/login";
            isRelogin.show = false;
            // router.push("/login");
          })
          .catch(() => {
            isRelogin.show = false;
          });
      }
      return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
    } else if (res.data.code === 500) {
      ElMessage({ message: res.data.msg, type: "error" });
      return Promise.reject(new Error(res.data.msg));
    } else if (res.data.code === 601) {
      ElMessage({ message: res.data.msg, type: "warning" });
      return Promise.reject(new Error(res.data.msg));
    } else if (res.data.code !== 200) {
      ElMessage({ message: res.data.msg, type: "error" });
      return Promise.reject("error");
    } else {
      return Promise.resolve(res.data);
    }
  },
  (error) => {
    switch (error.response.status) {
      case 500:
        console.log("服务器响应错误");
        break;
    }

    ElMessage({ message: error.message, type: "error", duration: 5 * 1000 });
    return Promise.reject(error);
  }
);

export default service;
