<template>
  <router-view />
</template>

<style lang="less">
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
</style>
