import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/company",
    component: () => import("@/views/Home"),
    children: [
      {
        path: "/company",
        component: () => import("@/views/Company"),
      },
      {
        path: "/agent",
        component: () => import("@/views/Agent"),
      },
      {
        path: "/dialog",
        component: () => import("@/views/Dialog"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/404",
    component: () => import("@/views/error/404"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  localStorage.setItem("activePath", to.path);
  // 登录放行
  if (to.path == "/login") return next();
  // 判断是否登录
  const isLogin = localStorage.getItem("agent-token");
  if (isLogin) {
    next();
  } else {
    next("/login");
  }
});

export default router;
