let api = {
  login: "/user/login", //登录
  enterprise: "/enterprise/list", //企业列表
  enterpriseAll: "/enterprise/listAll", //企业不分页列表
  enterpriseAdd: "/enterprise/add", //新增企业信息
  enterpriseUpdate: "/enterprise/update", //修改企业信息
  enterpriseDelete: "/enterprise/delete", //删除企业信息
  agent: "/agent/list", //智能体列表
  agentAdd: "/agent/add", //添加智能体
  agentDetail: "/agent/detail", //智能体详情
  agentDelete: "/agent/delete", //删除智能体
  agentUpdate: "/agent/update", //修改智能体
  dialog: "/conversations/list", //会话列表
  dialogDelete: "/conversations/delete", //删除会话
  dialogDetail: "/conversations/detail", //会话详情
  // enterprise: "/enterprise/list",
};

export default api;
